body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1e1e1e;
  border-bottom: 20px solid #333333;
  padding: 0%;
  margin: 0%;
  color: white;
  font-size: 150%;
  overflow-x: hidden;
}

body > * {
  font-family: Consolas, 'Courier New', monospace
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content 1fr;
  margin: 0 20px 0 20px;
}

@media only screen and (max-width: 450px) {
  body {
    font-size: 150%;
  }

  .layout {
    display: block;
    min-width: 450px;
  }
}

@media only screen and (min-width: 450px) {
  body {
    font-size: 150%;
  }

  .layout-rwd header, .layout-rwd main, .layout-rwd footer {
    grid-column: 1 / 13;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1000px) {
  body {
    font-size: 2.30vw;
  }

  .layout-rwd {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1400px) {
  body {
    font-size: 200%;
  }
}

.header-of-section {
  display: block;
  font-weight: 400;
  text-align: center;
  padding: 0px;
  margin: 0px;
  margin-bottom: 1vh;
  padding-bottom: 0px;
}

.header-of-section::after {
  content: "";
  display: block;
  margin: 0 auto;
  margin-bottom: 0px;
  width: 150px;
  padding-top: 20px;
  border-top: 5px solid #4d5d85;
  border-radius: 10% 10% 10% 10%;
}

.header-of-section span:nth-child(1),
.header-of-section span:nth-child(3)  {
  color: #727272;
}

.header-of-section span:nth-child(2) {
  color: #34be79;
}

/**************************

    Navigation Section

***************************/

  .navbar-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: max-content 1fr;
    margin-bottom: 8vh;
    border-radius: 0px 0px 10px 10px;
    background-color: #333333;
  }

  @media only screen and (max-width: 450px) {
    .navbar-section {
      display: block;
      min-width: 450px;
    }
  }

  /*
  @media only screen and (min-width: 450px) {
    .navbar-section-rwd div {
      grid-column: 1 / 13;
    }
  }
  
  @media only screen and (min-width: 1000px) {
    .navbar-section-rwd div {
      width: 950px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  */

  .navbar-section div {
    display: inline-block;
    padding: 20px 0 20px 0;
  }

  .navbar-section a {
    color: #a8a8a8;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: inherit;
    padding-bottom: inherit;
    margin-right: 10px;
    text-decoration: none;
  }

  .navbar-section a:hover {
    background-color: #5b5b5b;
  }
  
/**************************

    Profile Header Section

***************************/

  .profile-heading-section {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10vh;
  }

  .profile-heading-section img {
    margin-top: 50px;
    height: 152px;
    width: 152px;
    border-radius: 50%;
  }
  
  .profile-heading-section h1 {
    font-size: 2.5em;
    font-weight: 400;
    color: #0084dd;
    margin: 0px;
    padding: 0px;
    display: inline-block;
    overflow:hidden;
    border-left: 5px solid transparent;
    border-right: .15em solid #cb9648;
    border-bottom: 5px solid transparent;
    white-space: nowrap;
    margin: 0 auto;
    animation: 
      typing 2s steps(9, end), /* Takes two seconds to complete and animates in 9 increments */
      blink 1s step-start 2s forwards;
  }

  /* The typing effect */
  /* Indicates it goes from 0 characters to 9 characters of width and then stops */
  @keyframes typing {
    from { 
      width: 0;
    }
    to { 
      width: 9ch;
    } 
  }

  /* The typewriter cursor effect */
  /* Generates gold underline at the end and keeps it */
  @keyframes blink {
    from {
      border-right: .15em solid #cb9648;
    }
    to {
      border-right: .15em solid transparent;
      border-bottom: 5px solid #cb9648;
    }
  }

  .profile-heading-section h4 {
    width: fit-content;
    font-style: normal;
    font-weight: 400;
    color: #cb9648;
    margin: 0px;
    padding: 0px;
  }

  .profile-heading-section p {
    color: #008fee;
    font-style: italic;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    width: 95%;
  }

/**************************

    Skills Section 
    
**************************/

  .skills-section {
    margin-bottom: 8vh;
  }

  .skills-section__layout {
    display: block;
  }

  .skills-subheading {
    color: #6fccff;
    margin-top: 0;
    padding-top: 0;
  }
  
  .skills-subheading span {
    color: white;
  }

  .skills-subjects {
    margin: 0px 0px 0px 25px;
    padding: 0;
    list-style: none;
    color: #e28e6a; 
  }

  .skills-subjects li {
    display: inline-block;
    margin: 5px 5px 0px 5px;
  }

/**************************

    Project Section 
    
**************************/

  .project-section {
    margin-bottom: 10vh;
  }

  .project-card {
    display: grid;
    background-color: #dddddd;
    border: 8px solid #111111;
    border-radius: 20px;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 10px 8px 0 rgba(0,0,0,0.2);
    color: black;
    padding: 20px;
    margin-bottom: 50px;
    grid-template-rows: min-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-areas: "image text"
                         "image link";
    column-gap: 25px;
    text-align: center;
  }

  @media only screen and (max-width: 900px)  {
    .project-card {
      grid-template-areas: "image" "text" "skills" "link";
      grid-template-columns: 1fr;
      grid-template-rows: minmax(200px, max-content);
    }
  }

  /* On mouse-over, add a deeper shadow */
  .project-card:hover {
    box-shadow: 0 16px 20px 0 rgba(0,0,0,0.2);
  }

  .project-card h3 {
    margin: 20px 0px 0px 0px;
    padding: 0;
    font-size: 150%;
    font-weight: bold;
  }

  .project-card img {
    width: 300px;
    height: 300px;
    background: white;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    padding: 0;
    border-radius: 15px;
    grid-area: image;
  }

  @media only screen and (max-width: 900px)  {
    .project-card img {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
      height: 225px;
      width: 100%;
    }
  }

  .project-card-text {
    grid-area: text;
    text-align: left;
  }

  .project-card-links a {
    grid-area: link;
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    line-height: 50px;
    padding: 0 14px;
    background: #6b5dff;
    color: #fff;
    font-size: .9em;
    font-weight: 600;
    letter-spacing: .025em;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    border-radius: 10px;
    text-decoration: none;
    transition: all .15s ease;
  }

  .project-card-links a:hover {
    background:#9a91ff;
    text-decoration: none;
    transition: .1s ease-in-out
  }

  .project-card-skills {
    padding: 0;
    list-style-type: none;
  }

  .project-card-skills li {
    display: inline-block;
    border: 2px solid #0084dd;
    border-radius: 4px;
    padding: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

/**************************

      Footer Section

***************************/

  .footer-section--display {
    display: grid;
    grid-template-areas: "email" "linkedin" "github";
    margin-bottom: 25vh;
  }

  @media only screen and (min-width: 1000px) {
    .footer-section--display {
      grid-template-areas: "email linkedin github";
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .footer-section--display a {
    display: block;
    color: #11aaff;
    font-weight: 400;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer-section--display a:hover {
    color: #6b5dff;
  }

  .footer-section--display a::after {
    content: "";
    display: block;
    margin: 10px auto;
    width: 0px;
    border-top: 5px solid #9a93e1;
    border-radius: 10px;
    -webkit-transition: all 250ms;
    transition: all 250ms;
  }

  .footer-section--display a:hover::after {
    width: 50%;
  }
